<template>
    <div class="table-box">
        <el-table
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '600',
            }"
        >
            <el-table-column
                type="selection"
                width="55"
                v-if="
                    searchData.param &&
                    searchData.param.sourceCategory == 1 &&
                    btnP.disDep &&
                    isSelection
                "
                :selectable="handleDisable"
            >
            </el-table-column>
            <el-table-column prop="updateTime" label="创建时间" width="93">
                <template slot-scope="scope">
                    {{
                        $tableDataHandle.createTime({
                            createTime: scope.row.createTime,
                        })
                    }}
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="联系方式" width="180">
                <template slot="header">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;联系方式</div>
                </template>
                <template slot-scope="scope">
                    <div
                        v-if="
                            (scope.row.lineContactBOList &&
                                scope.row.lineContactBOList.length > 0) ||
                            (scope.row.customerMainContactBOList &&
                                scope.row.customerMainContactBOList.length > 0)
                        "
                    >
                        <el-tooltip
                            v-if="
                                scope.row.lineContactBOList &&
                                scope.row.lineContactBOList.length > 0
                            "
                            class="item"
                            effect="dark"
                            placement="bottom"
                        >
                            <div slot="content" class="way_box">
                                <p
                                    v-for="item in scope.row.lineContactBOList"
                                    :key="item.id"
                                >
                                    <span class="way_box_fs">
                                        <em>
                                            {{
                                                $tableDataHandle.contactWay(
                                                    item
                                                )
                                            }}</em
                                        >
                                        {{ item.contactContent }}
                                    </span>
                                    <span
                                        class="copy2"
                                        v-if="item.number > 1"
                                        @click="telSearch(item)"
                                    >
                                        <i
                                            class="iconfont icon-copy-rect-full"
                                        ></i>
                                        {{ item.number }}
                                    </span>
                                </p>
                            </div>
                            <div style="position: relative">
                                <span>
                                    <i
                                        :class="
                                            $tableDataHandle.contactWayColor(
                                                returnWay(scope.row)
                                            ).icon
                                        "
                                        :style="{
                                            color: $tableDataHandle.contactWayColor(
                                                returnWay(scope.row)
                                            ).color,
                                        }"
                                    ></i
                                    >{{ returnWay(scope.row).contactContent }}
                                </span>
                                <span
                                    class="copy"
                                    v-if="returnWay(scope.row).number > 1"
                                    @click="telSearch(returnWay(scope.row))"
                                >
                                    <i class="iconfont icon-copy-rect-full"></i>
                                    {{ returnWay(scope.row).number }}
                                </span>
                            </div>
                        </el-tooltip>
                        <el-tooltip
                            v-else-if="
                                scope.row.customerMainContactBOList &&
                                scope.row.customerMainContactBOList.length > 0
                            "
                            class="item"
                            effect="dark"
                            placement="bottom"
                        >
                            <div slot="content" class="way_box">
                                <p
                                    v-for="item in scope.row
                                        .customerMainContactBOList"
                                    :key="item.id"
                                >
                                    <span class="way_box_fs">
                                        <em>
                                            {{
                                                $tableDataHandle.contactWay(
                                                    item
                                                )
                                            }}</em
                                        >
                                        <em class="contactContent">{{
                                            item.contactContent
                                        }}</em>
                                        {{ item.contactName }}
                                    </span>
                                </p>
                            </div>
                            <div style="position: relative">
                                <span>
                                    <i
                                        :class="
                                            $tableDataHandle.contactWayColor(
                                                scope.row
                                                    .customerMainContactBOList[0]
                                            ).icon
                                        "
                                        :style="{
                                            color: $tableDataHandle.contactWayColor(
                                                scope.row
                                                    .customerMainContactBOList[0]
                                            ).color,
                                        }"
                                    ></i
                                    >{{
                                        scope.row.customerMainContactBOList[0]
                                            .contactContent
                                    }}
                                </span>
                            </div>
                        </el-tooltip>
                    </div>
                    <div v-else style="padding-left: 8px">- -</div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="编号" show-overflow-tooltip width="85">
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.lineNo ? scope.row.lineNo : '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="线索产品" width="100" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>
                        {{
                            scope.row.productClue
                                ? $tableDataHandle.productCategory(
                                      scope.row.productClue
                                  )
                                : '- -'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop=""
                label="线索类型"
                width="90"
                show-overflow-tooltip
            >
                <template slot-scope="scope"
                    ><div>
                        {{ $tableDataHandle.clueType(scope.row.clueType) }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="客户名称"
                show-overflow-tooltip
                prop="companyName"
                width="239"
            >
                <template slot-scope="scope">
                    <div>
                        {{
                            scope.row.companyName
                                ? scope.row.companyName
                                : '- -'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="需求"
                width="173"
                show-overflow-tooltip
                prop="remark"
            >
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.remark ? scope.row.remark : '- -' }}
                    </div>
                </template>
            </el-table-column>

            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="分配部门"
                width="80"
                prop="followDepartmentName"
            >
                <template slot-scope="scope">
                    <div>
                        {{
                            scope.row.followDepartmentName
                                ? scope.row.followDepartmentName
                                : '- -'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="负责人"
                prop="followAdminName"
                show-overflow-tooltip
                width="55"
            >
                <template slot-scope="scope">
                    <div>
                        {{
                            scope.row.followAdminName
                                ? scope.row.followAdminName
                                : '- -'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="用户填报" show-overflow-tooltip width="80">
                <template slot-scope="scope">
                    {{ scope.row.entryType == 0 ? '是' : '否' }}</template
                >
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="状态" width="80" show-overflow-tooltip>
                <template slot="header">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;状态</div>
                </template>
                <template slot-scope="scope">
                    <i
                        class="iconfont icon-ziyuanxhdpi"
                        @click="onHistory(scope.row)"
                    ></i>
                    {{
                        $tableDataHandle.clueStatus(scope.row.status)
                    }}</template
                >
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
    </div>
</template>
<script>
import { clueList } from '@/api/clue/meClue';
export default {
    components: {},
    data() {
        return {
            tableData: [],
            btnP: {},
            searchData: {},
        };
    },
    methods: {
        // 获取列表
        getData(btnP, data) {
            this.btnP = btnP;
            this.searchData = data;
            clueList(data).then((res) => {
                this.$emit('pageData', res.data.total);
                this.tableData = res.data.list;
            });
        },
        // 关闭抽屉
        onHistory(data) {
            this.$emit('onHistory', data);
        },
        returnWay(row) {
            let index = row.lineContactBOList.findIndex((item) => {
                return item.number > 0;
            });
            if (index == -1) {
                return row.lineContactBOList[0];
            } else {
                return row.lineContactBOList[index];
            }
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}

.icon-dianhua,
.icon-weixin,
.icon-QQ,
.icon-diannao,
.icon-youxiang,
.icon-youxiang1,
.icon-duanxinxiaoxixinxi,
.icon-qita,
.icon-yuyuebaifang,
.icon-qita1 {
    position: static;
    left: -10px !important;
}
.table-box {
    width: 100%;
    height: 100%;

    // /deep/ .el-table tr td {
    //     padding: 5px 0;
    // }
    .left_toast {
        position: absolute;
        top: 0;
        left: 10px;
        font-size: 12px;
        line-height: 1.2;
    }
}
.icon-ziyuanxhdpi {
    color: #2370eb;
    cursor: pointer;
    vertical-align: -1px;
}
.dl_con {
    width: 80%;
    margin: 35px auto 100px;
    text-align: center;
    color: #333;
    font-weight: 600;
    font-size: 14px;
    i {
        font-weight: 400;
        font-size: 60px;
        color: #f5a623;
        display: block;
        margin-bottom: 20px;
    }
}
.copy {
    height: 20px;
    background: #fff1d9;
    border-radius: 20px;
    line-height: 20px;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
    display: inline-block;
    padding: 0 8px 0 25px;
    color: #ff9c39;
    i {
        position: absolute;
        left: -10px;
        top: -10px;
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #ff9c39;
        color: #fff;
        text-align: center;
        line-height: 40px;
        font-size: 20px;
        transform: scale(0.5);
    }
}
.copy2 {
    height: 20px;
    background: #fff1d9;
    border-radius: 20px;
    line-height: 20px;

    font-size: 12px;
    display: inline-block;
    padding: 0 8px 0 25px;
    color: #ff9c39;
    position: relative;
    i {
        position: absolute;
        left: -10px;
        top: -10px;
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #ff9c39;
        color: #fff;
        text-align: center;
        line-height: 40px;
        font-size: 20px;
        transform: scale(0.5);
    }
}
.way_box_fs {
    line-height: 30px;
    em {
        display: inline-block;
        width: 30px;
        font-style: normal;
    }
    .contactContent {
        width: 120px !important;
        display: inline-block;
    }
}
.dialog_info {
    width: 80%;
    margin: 25px auto 150px;
    text-align: center;
    .info {
        font-size: 14px;
        margin-bottom: 16px;
        text-align: left;
        label {
            display: inline-block;
            width: 65px;
            font-weight: bold;
            color: #333;
            margin-left: 2px;
        }
    }
}
</style>
<style>
.el-checkbox__input.is-disabled .el-checkbox__inner {
    display: none;
}
</style>
