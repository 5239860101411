<template>
    <div class="clue_box">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <el-radio-group
                        v-model="searchVal.dateChoice"
                        size="small"
                        style="margin-left: 16px"
                    >
                        <el-radio-button
                            label="全部"
                            @click.native.prevent="clickitem('全部')"
                        ></el-radio-button>
                        <el-radio-button
                            label="今日"
                            @click.native.prevent="clickitem('今日')"
                        ></el-radio-button>
                        <el-radio-button
                            label="近7天"
                            @click.native.prevent="clickitem('近7天')"
                        ></el-radio-button>
                        <el-radio-button
                            label="本月"
                            @click.native.prevent="clickitem('本月')"
                        ></el-radio-button>
                    </el-radio-group>
                    <el-date-picker
                        style="width: 240px"
                        v-model="searchVal.searchDate"
                        size="small"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                        :picker-options="expireTimeOption"
                        clearable
                        @change="expireTime"
                    >
                    </el-date-picker>
                    <span v-if="btnP.depSearch">部门</span>
                    <el-cascader
                        v-if="btnP.depSearch"
                        size="small"
                        ref="cascader"
                        v-model="searchVal.createDepartmentId"
                        :options="departmentList"
                        :style="{ width: '205px' }"
                        :show-all-levels="false"
                        clearable
                        filterable
                        @change="changeDepartmentId"
                        :props="{
                            checkStrictly: true,
                            expandTrigger: 'hover',
                            emitPath: false,
                            value: 'id',
                            label: 'departmentName',
                            children: 'children',
                        }"
                    >
                        <template slot-scope="{ node, data }">
                            <div @click="cascaderClick(data)">
                                <span>{{ data.departmentName }}</span>
                            </div>
                        </template>
                    </el-cascader>
                </p>
            </div>
            <div class="search-three">
                <p class="xss">
                    <i class="iconfont icon-xiansuo1"></i>
                    <span>线索数</span>
                    {{ clueBriefData.lineTotal || '0' }}
                </p>
                <p class="yfp">
                    <i class="iconfont icon-xiansuo1"></i>
                    <span>已分配</span>
                    {{ clueBriefData.allotTotal || '0' }}
                </p>
                <p class="dfp">
                    <i class="iconfont icon-xiansuo1"></i>
                    <span>待分配</span>
                    {{ clueBriefData.toAllotTotal || '0' }}
                </p>
                <p class="gjz">
                    <i class="iconfont icon-xiansuo1"></i>
                    <span>跟进中</span>
                    {{ clueBriefData.followTotal || '0' }}
                </p>
                <p class="fq">
                    <i class="iconfont icon-xiansuo1"></i>
                    <span>放弃</span>
                    {{ clueBriefData.invalidTotal || '0' }}
                </p>
                <p class="wx">
                    <i class="iconfont icon-xiansuo1"></i>
                    <span>无效</span>
                    {{ clueBriefData.invalidTotal || '0' }}
                </p>
            </div>
        </div>
        <div class="search-two">
            <div class="search-two-title">市场线索</div>
            <p v-if="btnP.userSearch">
                <span style="margin-right: 38px">成员</span>
                <el-select
                    filterable
                    style="width: 120px"
                    v-model="searchVal.adminId"
                    size="small"
                    clearable
                    placeholder="请选择"
                    @change="(pagesize = 20), (currentPage = 1), getData()"
                >
                    <el-option
                        v-for="item in userName"
                        :key="item.id"
                        :label="item.nickName"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </p>
            <p>
                <!-- <span>线索产品</span>
                <el-select
                    v-model="searchVal.productClue"
                    size="small"
                    filterable
                    style="width: 120px"
                    clearable
                    placeholder="请选择"
                    @change="(pagesize = 20), (currentPage = 1), getData()"
                >
                    <el-option
                        v-for="item in $searchForm.productCategory()"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select> -->
                <clue-product
                    ref="clueproduct"
                    @changeProductId="changeProductId"
                    :productWidth="120"
                ></clue-product>
            </p>
            <p>
                <span>投放站点</span>
                <el-select
                    v-model="searchVal.launchId"
                    size="small"
                    filterable
                    style="width: 120px"
                    clearable
                    placeholder="请选择"
                    @change="(pagesize = 20), (currentPage = 1), getData()"
                >
                    <el-option
                        v-for="item in launchList"
                        :key="item.id"
                        :label="item.account"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </p>
            <p>
                <span>线索状态</span>
                <el-select
                    v-model="searchVal.status"
                    size="small"
                    filterable
                    style="width: 120px"
                    clearable
                    placeholder="请选择"
                    @change="(pagesize = 20), (currentPage = 1), getData()"
                >
                    <el-option label="待分配" :value="12"></el-option>
                    <el-option label="已分配" :value="14"></el-option>
                    <el-option label="已撤回" :value="13"></el-option>
                    <el-option label="跟进中" :value="5"></el-option>
                    <el-option label="已完成" :value="8"></el-option>
                    <el-option label="放弃" :value="15"></el-option>
                    <el-option label="无效" :value="4"></el-option>
                </el-select>
            </p>
            <p>
                <span>已分配部门</span>
                <el-cascader
                    size="small"
                    ref="cascader2"
                    v-model="searchVal.followDepartmentId"
                    :options="departmentList"
                    :style="{ width: '120px' }"
                    :show-all-levels="false"
                    clearable
                    filterable
                    @change="changeA"
                    :props="{
                        checkStrictly: true,
                        expandTrigger: 'hover',
                        emitPath: false,
                        value: 'id',
                        label: 'departmentName',
                        children: 'children',
                    }"
                >
                    <template slot-scope="{ node, data }">
                        <div @click="cascaderClick2(data)">
                            <span>{{ data.departmentName }}</span>
                        </div>
                    </template>
                </el-cascader>
            </p>
        </div>
        <div class="table_title">
            <p>
                <!-- <span>我的线索列表</span> -->
                <el-button
                    size="mini"
                    type="primary"
                    round
                    :class="searchVal.tableType == 1 ? 'isColor' : ''"
                    @click="(pagesize = 20), (currentPage = 1), onTab(1)"
                    >公司市场线索</el-button
                >
                <el-button
                    size="mini"
                    type="primary"
                    round
                    :class="searchVal.tableType == 3 ? 'isColor' : ''"
                    @click="(pagesize = 20), (currentPage = 1), onTab(3)"
                    >用户填报</el-button
                >
            </p>
        </div>
        <div class="table">
            <Table
                ref="table"
                @pageData="pageData"
                @onHistory="onHistory"
            ></Table>
        </div>
        <div class="page-box">
            <el-pagination
                v-if="isPage"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 线索历史 -->
        <el-drawer
            :visible.sync="drawerHistory"
            :direction="direction"
            :before-close="handleClose"
            size="480px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    线索历史
                </div>
            </template>
            <History @close="handleClose" ref="history" />
        </el-drawer>
    </div>
</template>

<script>
import Table from '../table/table.vue';
import { departmentList } from '@/api/admin/framework/framework.js';
import { launchList } from '@/api/user/launch/manage.js';
import { roleList } from '@/api/customer/customer.js';
import { clueHistory, clueBrief } from '@/api/clue/depClue.js';
import History from '../history/history.vue';
import clueProduct from '../../../../components/clueProduct.vue';
export default {
    components: {
        Table,
        History,
        clueProduct,
    },
    data() {
        return {
            searchVal: {
                name: '',
                dateChoice: '全部',
                searchDate: [],
                sourceCategory: 0,
                productClue: '',
                launchId: '',
                tableType: 1,
                followDepartmentId: '',
                status: '',
            },
            departmentList: [],
            userName: [],
            launchList: [],
            configList: [],
            drawerHistory: false,
            direction: 'rtl',
            btnP: {},
            pagesize: 20,
            total: 0,
            currentPage: 1,
            dialogDistribution: false,
            isDistribution: false,
            isPage: false,
            clueBriefData: {},
            followDepartmentId: '',
            expireTimeOption: {
                disabledDate(date) {
                    // disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
                    return date.getTime() >= Date.now();
                },
            },
        };
    },

    mounted() {
        this.getBtn();
    },
    methods: {
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;

            this.getLaunchList();

            this.getDepartmentList();
            if (!btn.depSearch && btn.userSearch) {
                this.getUserList(sessionStorage.getItem('departmentId'));
            }

            this.isPage = true;
            this.clueBrief();
            this.$refs.clueproduct.getData();
            this.getData();
        },
        cascaderClick(nodeData) {
            this.searchVal.adminId = '';
            // this.departmentId = ;
            this.searchVal.createDepartmentId = nodeData.id;
            this.getUserList(nodeData.id);
            this.clueBrief();
            this.$refs.cascader.checkedValue = nodeData.departmentName;
            this.$refs.cascader.computePresentText();
            this.$refs.cascader.toggleDropDownVisible(false);
        },
        clueBrief() {
            let data = {
                param: {
                    sourceCategory: 1,
                },
            };
            if (!this.btnP.depSearch) {
                data.param.createDepartmentId =
                    sessionStorage.getItem('departmentId');
            } else {
                data.param.createDepartmentId =
                    this.searchVal.createDepartmentId;
            }
            if (this.searchVal.dateChoice != '') {
                let date = new Date();
                data.param.endCreateDate = this.$searchTime.getNextDate(
                    date,
                    0
                );
                this.searchVal.searchDate = [];
                if (this.searchVal.dateChoice == '今日') {
                    data.param.startCreateDate = this.$searchTime.getNextDate(
                        date,
                        0
                    );
                } else if (this.searchVal.dateChoice == '近7天') {
                    data.param.startCreateDate = this.$searchTime.getNextDate(
                        date,
                        7
                    );
                } else if (this.searchVal.dateChoice == '本月') {
                    data.param.startCreateDate =
                        this.$searchTime.thisMonth()[0];
                } else if (this.searchVal.dateChoice == '全部') {
                    delete data.param.endCreateDate;
                }
            }

            if (
                this.searchVal.searchDate &&
                this.searchVal.searchDate.length > 0
            ) {
                data.param.startCreateDate = this.searchVal.searchDate[0];
                data.param.endCreateDate = this.searchVal.searchDate[1];
            }
            clueBrief(data).then((res) => {
                console.log(res);
                if (res.code == 200) {
                    this.clueBriefData = res.data;
                }
            });
        },
        changeDepartmentId(val) {
            this.searchVal.adminId = '';
            this.getData();
            this.getUserList(val);
            this.clueBrief();
        },
        // 获取部门成员
        getUserList(departmentId) {
            this.searchVal.followAdminId = '';
            let data = {
                param: {
                    departmentId,
                },
            };
            roleList(data).then((res) => {
                this.userName = res.data;
                console.log(!departmentId);
                if (!departmentId) {
                    this.userName = [];
                }
            });
        },

        // 投放列表
        getLaunchList() {
            let data = {
                param: { status: 1 },
                pageNum: 0,
                pageSize: 0,
            };
            launchList(data).then((res) => {
                if (res.code == 200) {
                    this.launchList = res.data.list;
                }
            });
        },
        onTab(i) {
            this.searchVal.tableType = i;
            this.getData();
        },
        changeProductId(val) {
            this.pagesize = 20;
            this.currentPage = 1;
            this.searchVal.productClue = val;
            this.getData();
        },
        // 搜索获取列表
        getData() {
            let data = {
                param: {
                    name: this.searchVal.name,
                    // dateChoice: this.searchVal.dateChoice,
                    // searchDate: this.searchVal.searchDate,
                    sourceCategory: this.searchVal.sourceCategory,
                    sourceCategory: 1,
                    tableType: this.searchVal.tableType,
                },
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            if (this.searchVal.dateChoice != '') {
                let date = new Date();
                data.param.endCreateDate = this.$searchTime.getNextDate(
                    date,
                    0
                );
                this.searchVal.searchDate = [];
                if (this.searchVal.dateChoice == '今日') {
                    data.param.startCreateDate = this.$searchTime.getNextDate(
                        date,
                        0
                    );
                } else if (this.searchVal.dateChoice == '近7天') {
                    data.param.startCreateDate = this.$searchTime.getNextDate(
                        date,
                        7
                    );
                } else if (this.searchVal.dateChoice == '本月') {
                    data.param.startCreateDate =
                        this.$searchTime.thisMonth()[0];
                } else if (this.searchVal.dateChoice == '全部') {
                    delete data.param.endCreateDate;
                }
            }

            if (
                this.searchVal.searchDate &&
                this.searchVal.searchDate.length > 0
            ) {
                data.param.startCreateDate = this.searchVal.searchDate[0];
                data.param.endCreateDate = this.searchVal.searchDate[1];
            }
            if (!this.btnP.depSearch) {
                data.param.createDepartmentId =
                    sessionStorage.getItem('departmentId');
            } else {
                data.param.createDepartmentId =
                    this.searchVal.createDepartmentId;
            }
            if (this.btnP.userSearch) {
                data.param.adminId = this.searchVal.adminId;
            } else {
                if (!this.btnP.depSearch) {
                    data.param.adminId = sessionStorage.getItem('adminId');
                }
            }
            if (this.searchVal.productClue != '') {
                data.param.productClue = this.searchVal.productClue;
            }
            if (this.searchVal.status != '') {
                data.param.status = this.searchVal.status;
            }
            if (this.searchVal.followDepartmentId != '') {
                data.param.followDepartmentId =
                    this.searchVal.followDepartmentId;
            }
            if (this.searchVal.tableType == 2) {
                data.param.adminId = sessionStorage.getItem('adminId');
                data.param.entryType = 1;
            }
            if (this.searchVal.launchId != '') {
                data.param.launchId = this.searchVal.launchId;
            }
            if (this.searchVal.tableType == 3) {
                data.param.entryType = 0;
            }
            setTimeout(() => {
                this.$refs.table.getData(this.btnP, data);
            }, 0);
        },
        cascaderClick2(nodeData) {
            this.searchVal.followDepartmentId = nodeData.id;
            this.$refs.cascader2.checkedValue = nodeData.departmentName;
            this.clueBrief();
            this.getData();
            this.$refs.cascader2.computePresentText();
            this.$refs.cascader2.toggleDropDownVisible(false);
        },
        changeA() {
            this.searchVal.followDepartmentId = '';
            this.clueBrief();
            this.getData();
        },
        pageData(i) {
            this.total = i;
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getData();
        },
        // 单选时间
        clickitem(e) {
            e === this.searchVal.dateChoice
                ? (this.searchVal.dateChoice = '')
                : (this.searchVal.dateChoice = e);
            this.searchVal.searchDate = [];
            this.getData();
            this.clueBrief();
        },
        // 时间输入框变化
        expireTime() {
            if (
                this.searchVal.searchDate &&
                this.searchVal.searchDate.length > 0
            ) {
                this.searchVal.dateChoice = '';
            }
            this.getData();
            this.clueBrief();
        },
        // 获取部门
        getDepartmentList() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                if (res.code == 200) {
                    this.followDepartmentId = '';
                    this.departmentList = this.$Tree.treeDataTranslate(
                        res.data
                    );
                }
            });
        },
        onHistory(row) {
            let data = {
                param: {
                    lineId: row.id,
                },
                pageSize: 0,
                pageNum: 0,
            };
            clueHistory(data).then((res) => {
                if (res.code == 200) {
                    this.drawerHistory = true;
                    setTimeout(() => {
                        this.$refs.history.getData(res.data.list);
                    }, 0);
                }
            });
        },
        // 关闭抽屉
        handleClose() {
            this.drawerHistory = false;
            this.getData();
        },
        searchEmpty() {
            this.searchVal = {
                name: '',
                dateChoice: '全部',
                searchDate: [],
                sourceCategory: 0,
                followAdminIdVal: '',
                productClueVal: '',
                tableType: 1,
                launchId: '',
                entryType: 1,
            };
            this.getBtn();
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.clue_box {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        p {
            display: inline-block;
            .el_radio_button_div {
                font-size: 14px;
                width: 70px;
            }
        }
        span {
            font-size: 12px;
            font-weight: 600;
            color: #000;
            margin: 0 16px;
        }
        .search-one {
            margin: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                button {
                    background: #2370eb;
                    border-radius: 2px;
                    border-color: #2370eb;
                }
                .el-range-editor--small.el-input__inner {
                    vertical-align: middle;
                    margin-left: 19px;
                }
            }
            .right {
                background: #2370eb;
                border-radius: 2px;
                border-color: #2370eb;
                margin-right: 16px;
            }
        }
        .search-three {
            display: flex;
            padding: 0 16px;
            margin-bottom: 10px;
            p {
                flex: 1;
                display: flex;
                margin-right: 30px;
                height: 48px;
                align-items: center;
                padding-left: 9px;
                padding-right: 30px;
                background: #f8f9fa;
                border-radius: 8px;
                border: 1px solid #d9d9d9;
                i {
                    width: 20px;
                    height: 20px;
                    border-radius: 2px;
                    display: inline-block;
                    color: #fff;
                    text-align: center;
                    line-height: 20px;
                    margin-right: 12px;
                }
                span {
                    flex: 1;
                    text-align: left;
                    margin: 0;
                    font-weight: normal !important;
                }
            }
            p:last-child {
                margin-right: 0;
            }
            .xss {
                i {
                    background: #3370ff;
                }
            }
            .yfp {
                i {
                    background: #ff8800;
                }
            }
            .dfp {
                i {
                    background: #7f3bf5;
                }
            }
            .gjz {
                i {
                    background: #36c723;
                }
            }
            .fq {
                i {
                    background: #f54b44;
                }
            }
            .wx {
                i {
                    background: #ff703b;
                }
            }
        }
    }

    .table_title {
        background: #fff;
        padding: 16px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        p {
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            button {
                background: #fff;
                border-color: #f0f0f0;
                color: #666666;
            }
            .isColor {
                border-color: #fff;
                color: #2370eb;
                background: #e9f2ff;
            }
        }
    }
    .table {
        flex: 1;
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .clue_btn {
        margin-top: 10px;
        position: absolute;
        top: -50px;
        right: 0;
        span {
            display: inline-block;
            padding: 10px 20px;
            margin-left: 15px;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            background-color: #fff;
        }
    }
}
.clue_assist_body {
    padding: 42px 74px 100px;
    .user {
        padding-bottom: 16px;
        display: flex;
        align-items: center;
        .left-spot {
            display: inline-block;
            width: 8px;
            height: 8px;
            background: #2370eb;
            border-radius: 50%;
            margin-right: 20px;
            margin-left: -22px;
        }
    }
    .user:last-child {
        .left-spot {
            display: inline-block;
            width: 7px;
            height: 7px;
            background: #fff;
            border: 1px solid #2370eb;
            border-radius: 50%;
            margin-right: 20px;
            margin-left: -22px;
        }
    }
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    background: #fff;
    justify-content: space-between;
    button {
        width: 72px;
    }
}

/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
}
/deep/ .el-textarea__inner {
    resize: none;
}
.search-two {
    background-color: #fff;
    border-radius: 4px;
    margin: 0 0 12px;
    .search-two-title {
        height: 50px;
        line-height: 50px;
        padding-left: 16px;
        font-size: 14px;
        font-weight: 600;
        color: #333;
        border-bottom: 1px solid #eeeeee;
    }
    p {
        display: inline-block;
        margin: 10px 0 12px;

        .el_radio_button_div {
            font-size: 14px;
            width: 70px;
        }
    }
    span {
        font-size: 12px;
        font-weight: 600;
        color: #000;
        margin: 0 16px;
    }
}
</style>
